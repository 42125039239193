




















































































































































































































































































    import { Component, Prop } from 'vue-property-decorator';
    import MixinMain from '@/mixing/MixinMain.vue';
    import { internet } from '@/utils/Internet';
    import Util from '@/utils/Util';
    import { Icon } from '@/model/util/Icon';
    import DataTable from '@/components/util/DataTable.vue';
	import { IDataTable } from '@/model/main/IDataTable';
	import { IHeaderTable } from '@/model/main/IHeaderTable';
    import { ILabels } from '@/model/labels/ILabels';
    import AppContainerBase from '@/components/util/AppContainerBase.vue';

    @Component({
            name: 'MainMalasProhibidas',
            components: {
                DataTable,
                AppContainerBase
        }})
        export default class MainMalasProhibidas extends MixinMain {
            public filter = "Palabras Prohibidas";
            public title = this.$t("MainPalabrasProhibidas.title");
            public message1 = this.$t("MainMotivos.message1");
            public subMessage1 = this.$t("MainMotivos.subMessage1");
            public subMessage2 = this.$t("MainMotivos.subMessage2");
            public message2 = this.$t("MainMotivos.message2");
            public message3 = this.$t("MainMotivos.message3");
            public message4 = this.$t("MainMotivos.message4");
            public message5 = this.$t("MainMotivos.message5");
            public message6 = this.$t("MainMotivos.message6");
            public message7 = this.$t("MainMotivos.message7");
            public message8 = this.$t("MainMotivos.message8");
            public message9 = this.$t("MainMotivos.message9");
            public message10 = this.$t("MainMotivos.message10");
            public message11 = this.$t("MainMotivos.message11");
            public message12 = this.$t("MainMotivos.message12");
            public message13 = this.$t("MainMotivos.message13");
            public message14 = this.$t("MainMotivos.message14");
            public message15 = this.$t("MainMotivos.message15");
            public message16 = this.$t("MainMotivos.message16");
            public message17 = this.$t("MainMotivos.message17");
            public message18 = this.$t("MainMotivos.message18");
            public btn1 = this.$t("MainMotivos.btn1");
            public btn2 = this.$t("MainMotivos.btn2");
            public btn3 = this.$t("MainMotivos.btn3");
            public btn4 = this.$t("MainMotivos.btn4");
            public btn5 = this.$t("MainMotivos.btn5");
            public btn6 = this.$t("MainMotivos.btn6");
            public btn7 = this.$t("MainMotivos.btn7");
            public btn8 = this.$t("MainMotivos.btn8");
            public elementPerPage = this.$t("DataTable.elementPerPage");
            public countElementPage = this.$t("DataTable.countElementPage");
            public isLoading = false;
            public dialogCreate = false;
            public dialogUpdate = false;
            public dialogDelete = false;
            public dialogMessageUpgrate = false;
            public dialogMessageDelete = false;
            public dialogMessageCreate = false; 
            public e6 = 1;
            public categories: Array<string> = [];
            public data: Array<ILabels> = [];
            public categorias:Array<string> = [];
            public campanias:Array<string> = [];
            public tipo_llamadas = [];
            public header: Array<IHeaderTable<IDataTable>> = [];
            public rows: Array<IDataTable> = [];
            public elemExplicitos = '';
            public selectedCateg:ILabels = {
                "Titulo": "",
                "Categoria": "",
                "Elementos_explicitos": [],
                "Campania": [],
                "Tipo_llamada": []
            };
            
            mounted(): void {
                this.getData();
            }
            
            private getData() {
                this.isLoading = true;
                const request_1 = internet
                    .newRequest()
                    .get(`getConfigScript?Titulo=${this.filter}`);
                const request_2 = internet
                    .newRequest()
                    .get(`getCampingsTipoLlam`);
                Util.waitForPromises([request_1, request_2])
                .then((responses) => {
                    this.campanias = responses[1].data.Campanias;
                    this.tipo_llamadas = responses[1].data.TipoLlamadas;
                    this.data = responses[0].data as Array<ILabels>;
                    //console.log(this.data);
                    const dataTable: Array<IDataTable> = [];
                    for (let item of this.data) {
                    // console.log(item);
                    const row: IDataTable = {};
                        row['Categoria'] = item.Categoria;
                        row['Elementos_explicitos'] = item.Elementos_explicitos;
                        row['Campania'] = item.Campania;
                        row['Tipo_llamada'] = item.Tipo_llamada;
                        row['Editar'] = "";
                        row['Eliminar'] = "";
                        //console.log(JSON.stringify(item.tunning))
                        dataTable.push(row);
                    }
                    const header: Array<IHeaderTable<
                        IDataTable
                    >> = Object.keys(dataTable[0]).map(
                        (
                            key: string
                        ): IHeaderTable<IDataTable> => {
                            let text = key;
                            switch (key) {
                                case 'Categoria':
                                    text = this.$t("selectedCategoria.categ") as string;
                                    break;
                                case 'Elementos_explicitos':
                                    text = this.$t("selectedCategoria.element") as string;
                                    break;
                                case 'Campania':
                                    text = this.$t("selectedCategoria.campaign") as string;
                                    break;
                                case 'Tipo_llamada':
                                    text = this.$t("selectedCategoria.typeCalls") as string;
                                    break;
                                case 'Editar':
                                    text = this.$t("selectedCategoria.edit") as string;
                                    break;
                                case 'Eliminar':
                                    text = this.$t("selectedCategoria.delete") as string;
                                    break;
                        }
                        return {
                            text,
                            value: key,
                                };
                        }
                    ) as Array<IHeaderTable<IDataTable>>;
                    this.rows = dataTable;
                    this.header = header;
                })
                .catch(console.log)
                .finally(() => {
                    this.isLoading = false;
                });
        }
        public openDialogUpdate(item:ILabels) {
            let texto = item.Elementos_explicitos.join(', ');
            this.selectedCateg = item;
            this.elemExplicitos = texto;
            this.dialogUpdate= true;
        }
        public updateData() {
            //console.log(this.selectedCateg);
            let reqHeader = [];
            reqHeader.push(`Titulo=${this.filter}`);
            reqHeader.push(`Categ=${this.selectedCateg.Categoria}`);
            this.elemExplicitos.split(', ').forEach(element => {
                reqHeader.push(`ElemExp[]=${element}`)
            });
            this.selectedCateg.Campania.forEach(element => {
                reqHeader.push(`Campings[]=${element}`)
            });
            this.selectedCateg.Tipo_llamada.forEach(element => {
                reqHeader.push(`TipoLlam[]=${element}`)
            });
            reqHeader.push("Visible=1");
            reqHeader.push("Particip=Cliente");
            //console.log(reqHeader.join('&'));
            const request = internet
                .newRequest()
                .get(`setConfigScript?${reqHeader.join('&')}`)
            Util.waitForPromises([request])
                .then((responses) => {
                    //console.log(responses[0].data.msg);
                    this.getData();
                })
                .catch(console.log)
                .finally(() => {
                    this.isLoading = false;
                });
        }
        public completeUpdate() {
            this.updateData();
            this.dialogUpdate=false;
            this.dialogMessageUpgrate=true;
        }
        public openDialogDelete(item:ILabels) {
            this.selectedCateg = item;
            this.dialogDelete= true;
        }
        public deleteData() {
            //console.log(this.selectedCateg);
            let reqHeader = [];
            reqHeader.push(`Titulo=${this.filter}`);
            reqHeader.push(`Categ=${this.selectedCateg.Categoria}`);
            this.elemExplicitos.split(', ').forEach(element => {
                reqHeader.push(`ElemExp[]=${element}`)
            });
            this.selectedCateg.Campania.forEach(element => {
                reqHeader.push(`Campings[]=${element}`)
            });
            this.selectedCateg.Tipo_llamada.forEach(element => {
                reqHeader.push(`TipoLlam[]=${element}`)
            });
            reqHeader.push("Visible=0");
            reqHeader.push("Particip=Cliente");
            //console.log(reqHeader.join('&'));
            const request = internet
                .newRequest()
                .get(`setConfigScript?${reqHeader.join('&')}`)
            Util.waitForPromises([request])
                .then((responses) => {
                    //console.log(responses[0].data.msg);
                    this.getData();
                    this.dialogDelete=false;
                    this.dialogMessageUpgrate=true;
                })
                .catch(console.log)
                .finally(() => {
                    this.isLoading = false;
                });
        }
        public openDialogCreate() {
            this.dialogCreate = true;
            this.elemExplicitos = '';
            this.selectedCateg = {
                "Titulo": this.filter,
                "Categoria": "",
                "Elementos_explicitos": [],
                "Campania": [],
                "Tipo_llamada": []
            };
            this.categorias = this.data.map(
                (elemento) => {
                    return elemento.Categoria
                }
            );
        }
        public toStep2() {
            if (this.selectedCateg.Categoria == '') {
                Util.showMessage(
                    this.message14 as string,
                    Icon.INFO
                );
            } else if (this.categorias.includes(this.selectedCateg.Categoria)) {
                Util.showMessage(
                    this.message15 as string,
                    Icon.INFO
                );
            } else {
                this.e6= 2;
                this.data.map(
                    (elemento) => {
                        if(elemento.Categoria == this.selectedCateg.Categoria) {
                            this.selectedCateg = elemento;
                            this.elemExplicitos = elemento.Elementos_explicitos.join(', ');
                        }
                    }
                );
            }
        }
        public toStep3() {
            if (this.elemExplicitos == '') {
                Util.showMessage(
                    this.message16 as string,
                    Icon.INFO
                );
            } else {
                this.e6= 3;
            }
        }
        public completeSteps() {
            if (this.selectedCateg.Campania.length < 1) {
                Util.showMessage(
                    this.message17 as string,
                    Icon.INFO
                );
            } else if (this.selectedCateg.Tipo_llamada.length < 1) {
                Util.showMessage(
                    this.message18 as string,
                    Icon.INFO
                );
            } else {
                this.updateData();
                this.selectedCateg = {
                    "Titulo": "",
                    "Categoria": "",
                    "Elementos_explicitos": [],
                    "Campania": [],
                    "Tipo_llamada": []
                };
                this.elemExplicitos = '';
                this.categories = [];
                this.dialogCreate = false;
                this.dialogMessageCreate = true;
                this.e6 = 1;
            }
        }
        public getTextCountElementPerPage(
                to: number,
                end: number
            ): string {
                let text = Util.replaceTextWith(
                    this.countElementPage as string,
                    '$1',
                    to.toString()
                );
                text = Util.replaceTextWith(
                    text,
                    '$2',
                    end.toString()
                );
                return text;
            }

        }
