






















    import { Component, Prop } from 'vue-property-decorator';
    import MixinMain from '@/mixing/MixinMain.vue';
    import AppContainerBase from '@/components/util/AppContainerBase.vue';
    import DataTable from '@/components/util/DataTable.vue';
    import Util from '@/utils/Util';
    import BarChart from '@/components/util/BarChart.vue';
    import MainMotivos from './MainMotivos.vue';
    import { IItemSelect } from '@/model/util/IItemSelect';
    import MainTags from './MainTags.vue';
    import MainMalasPalabras from './MainMalasPalabras.vue';
    import MainPalabrasProhibidas from './MainPalabrasProhibidas.vue';
    import MainTerminosColoquiales from './MainTerminosColoquiales.vue';

    @Component({
		name: 'MainTagsAudios',
        components: {
			AppContainerBase,
            DataTable,
            BarChart,
            MainMotivos,
            MainMalasPalabras,
            MainPalabrasProhibidas,
            MainTerminosColoquiales
    }})
    export default class MainTagsAudios extends MixinMain {
        public title = this.$t("MainMotivos.title");
        public tabs = null;
        public tabItems: Array<IItemSelect> = [
			{
				text: this.$t("MainMotivoMora.title") as string,
                value: MainMotivos
			},
            {
				text: this.$t("MainMalasPalabras.title") as string,
                value: MainMalasPalabras
			},
            {
				text: this.$t("MainPalabrasProhibidas.title") as string,
                value: MainPalabrasProhibidas
			},
			{
				text: this.$t("MainTerminosColoquiales.title") as string,
                value: MainTerminosColoquiales
			},
		];

    }
