
import { ICamping } from '@/model/main/ICamping';
import { IEvCat } from '@/model/main/IEvCat';
import { IFilterData } from '@/model/main/IFilterData';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const storeMain = namespace('storeMain');
@Component
export default class MixinMain extends Vue {
	@storeMain.Getter
	public getFilter!: IFilterData;

	@storeMain.Getter
	public getFilterUrl!: string;

	@storeMain.Getter
	public getOrderCat!: Array<string>;

	@storeMain.Getter
	public getEvCat!: Array<IEvCat>;

	@storeMain.Getter
	public getCamping!: Array<ICamping>;

	@storeMain.Action
	public updateFilter!: (payload: IFilterData) => void;

	@storeMain.Action
	public updateEvCat!: (ivcats: Array<IEvCat>) => void;

	@storeMain.Action
	public updateCamping!: (campings: Array<ICamping>) => void;

	@storeMain.Action
	public clearFilter!: () => void;
}
