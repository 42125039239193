

































import {
	Component,
	Emit,
	Prop,
	Vue,
} from 'vue-property-decorator';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { DataTable as Text } from '@/config/Text.json';
import Util from '@/utils/Util';
@Component({
	name: 'DataTable',
})
export default class DataTable extends Vue {
	public noDataText = this.$t("DataTable.noDataText");
	public noDataFound = this.$t("DataTable.noDataFound");
	public elementPerPage = this.$t("DataTable.elementPerPage");
	public countElementPage = this.$t("DataTable.countElementPage");
	public searchText = this.$t("DataTable.searchText");
	public search = '';
	@Prop({
		type: Array as () => Array<IDataTable>,
		required: true,
	})
	readonly data!: Array<IDataTable>;
	@Prop({
		type: Array as () => Array<string>,
		required: true,
	})
	readonly titles!: Array<string>;
	public getTextCountElementPerPage(
		to: number,
		end: number
	): string {
		let text = Util.replaceTextWith(
			this.countElementPage as string,
			'$1',
			to.toString()
		);
		text = Util.replaceTextWith(
			text,
			'$2',
			end.toString()
		);
		return text;
	}
	get headers(): Array<IHeaderTable<IDataTable>> {
		let output: Array<IHeaderTable<IDataTable>> = [];
		if (
			this.data.length > 0 &&
			Object.keys(this.data[0]).length ===
				this.titles.length
		) {
			this.titles.forEach(
				(value: string, index: number) => {
					output.push({
						text: value,
						value: Object.keys(this.data[0])[
							index
						],
					});
				}
			);
		}
		return output;
	}

	filterByText(
		value: string,
		search: string,
		item: IDataTable
	) {
		return (
			value != null &&
			search != null &&
			value.toString().indexOf(search) !== -1
		);
	}

	@Emit()
	public onRowClicked(data: string, item: IDataTable) {
		return item.item;
	}
}
